import Script from 'next/script';

function Scripts() {
	return (
		<>
			<Script
				src={`${process.env.BACKEND_URL}/public/assets/editor/js/custom_slider.min.js`}
			></Script>
		</>
	);
}
export default Scripts;
