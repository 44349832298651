import {useRouter} from 'next/router';
import ErrorPage from 'next/error';

import Scripts from '@components/cms/HeadScript.component';
import Cms from '@components/cms/Cms.component';
import Loading from '@components/Loading';
import {getCmsPaths, getCmsPageProps} from '@utils/cms';

const Page = props => {
	const {cmsdata} = props;
	const router = useRouter();
	if (!router.isFallback && !cmsdata) {
		return <ErrorPage statusCode={404} />;
	}

	return router.isFallback ? (
		<Loading />
	) : (
		<>
			<Scripts />
			<Cms cmsdata={cmsdata}></Cms>
			<div id='dynamicstyle'></div>
		</>
	);
};

export async function getStaticProps({params}) {
	const slug = params && params.slug ? params.slug.join('/') : '/';

	const props = await getCmsPageProps(slug);

	let meta = {};
	if (props && props.result && props.result.cms) {
		meta = {
			title: props.result.cms.title,
			description: props.result.cms.meta_desc,
			keywords: props.result.cms.meta_keyword,
		};
	}
	return {
		props: {
			cmsdata: props?.result?.cms || null,
			meta,
		},
		revalidate: 60 * 15,
	};
}

export async function getStaticPaths() {
	const paths = await getCmsPaths();

	delete paths[0];
	return {
		paths:
			paths.map(node =>
				node.slug === '/' ? `${node.slug}` : `/${node.slug}`,
			) || [],
		fallback: true,
	};
}

Page.Layout = true;
export default Page;
